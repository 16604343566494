import * as actionTypes from './actions';
import config from './../config';
export const initialState = {
	userDetails: [],
	profileData: {},
	...config
};

const userReducer = (state = initialState, action) => {
	// let trigger = [];
	// let open = [];
	console.log(action.data, '12');
	switch (action.type) {
		case actionTypes.USER_LOGIN:
			return {
				...state,
				userDetails: action.data
			};
		case actionTypes.USER_PROFILE_DATA:
			return {
				...state,
				profileData: action.data
			};
		default:
			return state;
	}
};
export default userReducer;
